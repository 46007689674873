import React from 'react';

import { Products } from 'components';
import content from './content';
import getHomeData from '../../queries/home.module';

const Home = () => {
  const { allContentfulProducts } = getHomeData();

  const homeData = allContentfulProducts
    ? allContentfulProducts?.edges[0]?.node?.typeOfProducts
    : [];
  return <Products content={content} homeData={homeData} />;
};

export default Home;
