import React from 'react';

import { Layout, SEO } from 'components';
import Home from '../containers/home/Home';

const IndexPage = () => (
  <Layout page='Products'>
    <SEO title='Shop for you smart pet' />
    <Home />
  </Layout>
);

export default IndexPage;
