import { useStaticQuery, graphql } from 'gatsby';

const getHomeData = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulProducts {
          edges {
            node {
              allProducts
              typeOfProducts {
                description {
                  json
                }
                id
                slug
                title
                url
                previewImage {
                  fluid(maxWidth: 280, maxHeight: 280, quality: 100) {
                    ...GatsbyContentfulFluid
                  }
                }
                shortDescription
                amazonLogo {
                  fixed(width: 70) {
                    aspectRatio
                    base64
                    height
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                    width
                  }
                }
                price
                productLink
                images {
                  fluid(quality: 70) {
                    ...GatsbyContentfulFluid
                  }
                }
                productVideo {
                  file {
                    url
                  }
                }
                productVideoMobile {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  return data;
};

export default getHomeData;
